// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import { container } from '../components/layout.module.css'
import { StaticImage } from "gatsby-plugin-image"

// Step 2: Define your component
const LearnPage = () => {
  return (
    <div className={container}>
      <main>
        <Layout>

          <StaticImage src="../../static/1-learn.jpg" alt="Get Into Investing!" layout="constrained"/>

          <table align="center" width="90%">
            <tr>
              <td>
              <div><br />
                <form name="contact" method="POST">
                  <p>
                    <label>Your Name: <input type="text" name="name" /></label>   
                  </p>
                  <p>
                    <label>Your Email: <input type="email" name="email" /></label>
                  </p>
                  <p>
                    <button type="submit">Send</button>
                  </p>
                </form>
              </div>
              </td>  
            </tr>
          </table>

        </Layout>
      </main>
    </div>
  )
}

// Step 3: Export your component
export default LearnPage